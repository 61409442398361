<template>
  <div class="card cartao" :class="props.class">
    <CustomPicture
      v-if="imagem && local"
      :imagem="imagem"
      class="card-img-top"
    />

    <nuxt-img
      v-if="imagem && !local"
      loading="lazy"
      class="card-img-top"
      :width="props.width"
      :height="props.height"
      :alt="imagem"
      :src="imagem"
    />

    <h2 v-if="titulo" class="card-title mt-4">{{ titulo }}</h2>

    <div v-if="texto" class="my-4 mx-3">
      <a
        v-for="texto in textoRef"
        v-bind:key="texto"
        class="card-text d-block text-decoration-none"
      >
        {{ texto }}
      </a>
    </div>

    <p v-if="subtitulo" class="card-text">
      <a
        v-for="subtitulo in subtituloRef"
        v-bind:key="subtitulo"
        class="card-text d-block text-decoration-none mb-2"
      >
        <strong>{{ subtitulo }}</strong>
      </a>
    </p>

    <div v-if="botao">
      <button
        :hidden="clicando"
        @click.prevent="clicado(localePath(botao?.url, locale))"
      >
        {{ botao?.label }}
      </button>

      <span :hidden="!clicando" class="spinner-border" role="status"></span>
    </div>
  </div>
</template>

<script setup>
const clicando = ref(false);
const { locale } = useI18n();
const localePath = useLocalePath();
const props = defineProps({
  class: String,
  titulo: String,
  local: { type: Boolean, default: false },
  imagem: String,
  texto: String,
  subtitulo: String,
  botao: Object,
  width: String,
  height: String,
});

const textoRef = ref(props.texto ? props.texto.split("#") : []);
const subtituloRef = ref(props.subtitulo ? props.subtitulo.split("#") : []);

const clicado = (url) => {
  clicando.value = ref(true);
  navigateTo(url);
  clicando.value = ref(false);
};
</script>

<script>
export default {
  name: "CustomCartao",
};
</script>
